







































































































import Labor from '@/entidades/Plantacion/Labor';
import TipoLabor from '@/entidades/Plantacion/TipoLabor';
import UnidadMedida from '@/entidades/Plantacion/UnidadMedida';
import Vue from 'vue'
import {Prop,Component} from 'vue-property-decorator';
import {Validate, Validations} from 'vuelidate-property-decorators';
import {required,numeric,minValue,decimal} from 'vuelidate/lib/validators'
import { Action } from 'vuex-class';

@Component
export default class FormularioLabor extends  Vue
{
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
   @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @Prop({type:Number,required:true})
    action!:number;

    @Prop({type:String,required:true})
    titulo!:string;


    @Prop({type:Object,required:true})
    labor!:Labor;


    @Prop({type:Array,required:true})
    listadoMedida!:Array<UnidadMedida>;


    @Prop({type:Array,required:true})
    listadoTipoLabor!:Array<TipoLabor>;

     //variables
     @Validations()
     validations = {
         codigo : {required},
         descripcion : {required},
         cuentaContable: {required},
         precioUnitario :{required,decimal,minValue:minValue(0)},
         precioUnitarioContratista :{required,decimal,minValue:minValue(0)},
         tipoLaborId : {required,minValue:minValue(1)},
         unidadMedidaId: {required,minValue:minValue(1)},

     }
     codigo:string="";
     descripcion:string ="";
     cuentaContable:string ="";
     precioUnitario:number= 0;
     precioUnitarioContratista:number= 0;
     tipoLaborId:number = 0;
     unidadMedidaId:number=0;
     laborId:number=0;

    //metodos
    guardarInfo()
    {
        this.labor.laborId = this.laborId;
        this.labor.descripcion = this.descripcion;
        this.labor.codigo = this.codigo;
        this.labor.cuentaContable = this.cuentaContable;
        this.labor.precioUnitario = Number(this.precioUnitario);
        this.labor.unidadMedidaId = this.unidadMedidaId;
        this.labor.tipoLaborId = this.tipoLaborId;
        this.labor.precioUnitarioContratista = Number(this.precioUnitarioContratista);
        console.log('informacion a enviar',JSON.stringify(this.labor));
        this.$emit('guardar');
    }
   //Computed
    get texto()
    {
        if(this.action == 1)
        {
            return "GUARDAR";
        }
        else
        {
            this.codigo = this.labor.codigo;
            this.descripcion = this.labor.descripcion;
            this.cuentaContable = this.labor.cuentaContable;
            this.precioUnitario = this.labor.precioUnitario;
            this.unidadMedidaId = this.labor.unidadMedidaId;
            this.tipoLaborId = this.labor.tipoLaborId;
            this.laborId  = this.labor.laborId;
            this.precioUnitarioContratista = this.labor.precioUnitarioContratista;
            return "EDITAR";
        }
    }

    get MensajeErrorPrecioUnitario()
    {
        const errors: string[] = [];
        if(!this.$v.precioUnitario.$dirty) return errors;
        !this.$v.precioUnitario.minValue && errors.push("Valor minimo debe ser mayor a cero");
        !this.$v.precioUnitario.required && errors.push("El Campo es requerido");
        return errors;
    }

    get MensajeErrorPrecioUnitarioContratista()
    {
        const errors: string[] = [];
        if(!this.$v.precioUnitarioContratista.$dirty) return errors;
        !this.$v.precioUnitarioContratista.minValue && errors.push("Valor minimo debe ser mayor a cero");
        !this.$v.precioUnitarioContratista.required && errors.push("El Campo es requerido");
        return errors;
    }

    get MensajeTipoLabor()
    {
        const errors: string[] = [];
        if(!this.$v.tipoLaborId.$dirty) return errors;
        !this.$v.tipoLaborId.minValue && errors.push("Valor minimo es 1");
        !this.$v.tipoLaborId.required && errors.push("El Campo es requerido");
        return errors;
    }

    get MensajeUnidad()
    {
         const errors: string[] = [];
        if(!this.$v.unidadMedidaId.$dirty) return errors;
        !this.$v.unidadMedidaId.minValue && errors.push("Valor minimo es 1");
        !this.$v.unidadMedidaId.required && errors.push("El Campo es requerido");
        return errors;
    }





    mounted()
    {

    }
}
