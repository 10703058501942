








































import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import TablaGeneral from "@/components/General/TablaGeneral.vue";
import Labor from "@/entidades/Plantacion/Labor";
import Formulario from "@/components/Maestro/Labor/Formulario.vue";
import UnidadMedida from "@/entidades/Plantacion/UnidadMedida";
import TipoLabor from "@/entidades/Plantacion/TipoLabor";
import ResponseGenerico from "@/entidades/ResponseGenerico";
import { Mutation } from "vuex-class";
import Loading from "@/entidades/Sistema/Loading";
import Alerta from "@/entidades/Sistema/Alerta";

@Component({
  components: {
    TablaGeneral,
    Formulario,
  },
})
export default class LaborView extends Vue {
  @Mutation("mostrarLoading", { namespace: "sistemaModule" })
  mostrarLoading!: Function;
  @Mutation("mostarAlert", { namespace: "sistemaModule" })
  mostarAlert!: Function;

  //Propiedas y atributos
  header: { text: string; sortable: boolean; value: string }[] = [
    { text: "Tipo Labor", sortable: true, value: "tipoLabor" },
    { text: "Codigo", sortable: true, value: "codigo" },
    { text: "Descripcion", sortable: true, value: "descripcion" },
    { text: "Cta. Contable", sortable: true, value: "cuentaContable" },
    { text: "Editar", sortable: true, value: "actions" },
    { text: "Estado", sortable: true, value: "estado" },
  ];
  listadoLabor: Array<Labor> = [];
  search: string = "";
  modalAgregarLabor: boolean = false;
  titulo: string = "";
  action: number = 1;
  modalAgregarLaborName: string = "";
  laborModelo: Labor | undefined = new Labor();
  listadoUnidad: Array<UnidadMedida> = [];
  listadoTipoLabor: Array<TipoLabor> = [];

  //Metodos
  async abrirModalAgregar() {
    //iremos a la base de datos a traer informacion del listado del tipo de labor
    try {
      this.mostrarLoading(new Loading(true, "Obteniendo Registros"));
      let response = await TipoLabor.ObtenerSelect();
      if (response.isResult.length == 0) {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 2000));
        return;
      }
      this.listadoTipoLabor = response.isResult;
      //obtenemos la undiad de medida
      let responseMedida = await UnidadMedida.ObtenerSelect();
      if (responseMedida.isResult.length == 0) {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 2000));
        return;
      }
      this.listadoUnidad = responseMedida.isResult;
    } catch (error) {
         //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 2000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
    this.laborModelo = new Labor();
    this.action = 1;
    this.titulo = "Registro Labor";
    this.modalAgregarLabor = true;
    this.modalAgregarLaborName = "Formulario";
  }

  async modalEditar(labor: Labor) {
    try {
      this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
      let response = await TipoLabor.ObtenerSelect();
      if (response.isResult.length == 0) {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 2000));
        return;
      }
      this.listadoTipoLabor = response.isResult;
      //obtenemos la undiad de medida
      let responseMedida = await UnidadMedida.ObtenerSelect();
      if (responseMedida.isResult.length == 0) {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 2000));
        return;
      }
      this.listadoUnidad = responseMedida.isResult;
    }catch (error) {
         //@ts-ignore
      this.mostarAlert(new Alerta(error,true,"danger",2000));
    }finally{
            this.mostrarLoading(new Loading(false,""));
    }
    this.laborModelo = labor;
    this.action = 2;
    this.titulo = "Editar Labor";
    this.modalAgregarLaborName = "Formulario";
    this.modalAgregarLabor = true;
  }
  cerrarModal() {
    this.action = 1;
    this.modalAgregarLabor = false;
    this.modalAgregarLaborName = "";
    this.laborModelo = undefined;
  }

  async guardarDb() {
    this.mostrarLoading(new Loading(true, "Obteniendo Registros"));
    console.log("entramos en el metodo guardar");
    let response: ResponseGenerico = new ResponseGenerico(false, "");
    try {
        this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
      if (this.laborModelo != undefined) {
        console.log("fuimos a la base de datos");
        if (this.action == 1) {
          response = await Labor.Guardar(this.laborModelo);
        } else {
          response = await Labor.Editar(this.laborModelo);
        }
      } else {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 2000));
        return;
      }
      console.log(this.laborModelo);
      console.log(response);
      if (response.isSuccess == true) {
        this.modalAgregarLabor = false;
        this.modalAgregarLaborName = "";
        this.mostarAlert(new Alerta(response.isMessage, true, "primary", 2000));
        await this.ObtenerLabor();
      } else {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 2000));
      }
    } catch (error) {
         //@ts-ignore
      this.mostarAlert(new Alerta(error,true,"danger",2000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }
  async editarEstado(tipoLabor: Labor) {
    try {
      this.mostrarLoading(new Loading(true,"Registrando"));
      let response = await Labor.EditarEstado(tipoLabor);
      if (response.isSuccess == true) {
        this.mostarAlert(new Alerta(response.isMessage, true, "primary", 2000));
        this.ObtenerLabor();
      } else {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 2000));
      }
    } catch (error) {
         //@ts-ignore
      this.mostarAlert(new Alerta(error,true,"danger",2000));
    }finally{
            this.mostrarLoading(new Loading(false,""));
        }
  }

  async ObtenerLabor() {
    try {
      this.mostrarLoading(new Loading(true, "Obteniendo Registros"));
      let response = await Labor.Obtener();
      if (response.isSuccess == true) {
        this.listadoLabor = response.isResult;
      } else {
        this.mostarAlert(new Alerta(response.isMessage,true,"danger",2000));
      }
    } catch (error) {
         //@ts-ignore
        this.mostarAlert(new Alerta(error,true,"danger",2000));
    }finally{
            this.mostrarLoading(new Loading(false,""));
        }
  }

  mounted() {
    this.ObtenerLabor();
  }
}
