import ResponseCustom from '../ResponseCustom';
import ResponseGenerico from '../ResponseGenerico';
import {Obtener,Save,Editar} from '../../Generico/Conexion';
import { mainAxios } from '@/plugins/axios'
export default class TipoLabor
{
    tipoLaborId:number = 0;
    descripcion:string = "";
    estado:boolean = true;
    countActivas:number = 0;
    countInactivas:number =0;


    static async Obtener():Promise<ResponseCustom<TipoLabor>>
    {
      return await Obtener<TipoLabor>("TipoLabor/Obtener",mainAxios);
    }

    static async ObtenerSelect():Promise<ResponseCustom<TipoLabor>>
    {
      return await Obtener<TipoLabor>("TipoLabor/ObtenerSelect",mainAxios);
    }

    static async Guardar(tipoLabor:TipoLabor):Promise<ResponseGenerico>
    {
        return await Save<TipoLabor>("TipoLabor/Guardar",tipoLabor,mainAxios);
    }

    static async EditarEstado(tipoLabor:TipoLabor):Promise<ResponseGenerico>
    {
        return await Editar<TipoLabor>("TipoLabor/EditarEstado",tipoLabor,mainAxios);
    }

    static async Editar(tipoLabor:TipoLabor):Promise<ResponseGenerico>
    {
        return await Editar<TipoLabor>("TipoLabor/Editar",tipoLabor,mainAxios);
    }
}