import ResponseCustom from '../ResponseCustom';
import ResponseGenerico from '../ResponseGenerico';
import {Obtener,Save,Editar} from '../../Generico/Conexion';
import { mainAxios } from '@/plugins/axios'
export default class Labor
{
    laborId: number = 0;
    descripcion:string="";
    codigo:string="";
    precioUnitario:number= 0;
    precioUnitarioContratista:number=0;
    cuentaContable: string = "";
    unidadMedidaId:number = 0;
    estado:boolean= true;
    tipoLabor:string="";
    tipoLaborId: number =  0


    static async Obtener():Promise<ResponseCustom<Labor>>
    {
      return await Obtener<Labor>("Labor/Obtener",mainAxios);
    }

    static async ObtenerSelect():Promise<ResponseCustom<Labor>>
    {
      return await Obtener<Labor>("Labor/ObtenerSelect",mainAxios);
    }

    static async Guardar(labor:Labor):Promise<ResponseGenerico>
    {
        return await Save<Labor>("Labor/Guardar",labor,mainAxios);
    }

    static async EditarEstado(labor:Labor):Promise<ResponseGenerico>
    {
        return await Editar<Labor>("Labor/EditarEstado",labor,mainAxios);
    }

    static async Editar(labor:Labor):Promise<ResponseGenerico>
    {
        return await Editar<Labor>("Labor/Editar",labor,mainAxios);
    }


}